import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div>Barış Can Akkoçak © 2022</div>
    </div>
  );
};

export default Footer;
